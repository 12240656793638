@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200&display=swap');


$mainColor: #1E1E1E;
$mainWhite: #fff;


@mixin portrait {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin mini-tablet-portrait {
    @media (max-width: 800px) {
        @content;
    }
}

@mixin landscape {
    @media (max-width: 950px) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (max-width: 1400px) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (max-width: 1100px) {
        @content;
    }
}



$Landscapewidth: 950px;

@mixin mobile {
    @media (max-width: #{$Landscapewidth}) {
        @content
    }
}

;