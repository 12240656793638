@import '../../global.scss';

.contact {
    display: flex;

    @include portrait {
        flex-direction: column;
    }

    @include tablet-portrait {
        flex-direction: column;
    }

    .left {
        flex: 1;
        overflow: hidden;

        img {
            height: 100%;
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
            font-size: 30px;
        }

        form {
            width: 70%;
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            @include portrait {
                width: 400px;
                height: 300px;
            }

            @include tablet-portrait {
                width: 400px;
                height: 300px;
            }


            input {
                width: 300px;
                height: 30px;
                font-size: 14px;
                border-radius: 8px;
                border: 2px solid black;

                @include tablet-portrait {
                    width: 300px;
                    height: 50px;
                }


            }

            textarea {
                width: 300px;
                font-size: 14px;

                #textareh1 {
                    font-size: 20px;
                    color: green;
                }


            }

            button {
                width: 150px;
                height: 30px;
                background-color: crimson;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;

                @include landscape {
                    margin-top: 30px;
                }

            }

            span {
                color: green;
                font-size: 17px;
            }

            #hidden {
                display: none;
            }



        }
    }
}