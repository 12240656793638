@import '../../global.scss';


.portfolio {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-type: unset;

  h1 {
    font-size: 45px;
    font-family: 'Yanone Kaffeesatz', sans-serif;

    @include portrait {
      font-size: 20px;
    }


  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include portrait {
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }

  }

  .container {
    margin-top: 50px;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include portrait {
      width: 100%;
    }

    .item {
      width: 220px;
      height: 150px;
      border-radius: 20px;
      border: 1px solid rgb(240, 239, 239);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .5s ease;
      cursor: pointer;

      @include portrait {
        overflow: hidden;
        width: 130px;
        height: 100px;
      }

      h3 {
        position: absolute;
        font-size: 20px;

        @include portrait {
          font-size: 15px;
        }
      }

      a {
        position: absolute;
        font-size: 20px;
        text-decoration: underline;
        color: $mainWhite;
      }

      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        z-index: 1;
      }

      &:hover {
        background-color: $mainColor;

        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}