@import '../../global.scss';

.work {
    background-color: rgb(128, 25, 45);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .arrow {
        height: 50px;
        position: absolute;

        @include portrait {
            display: none;
        }

        &.left {
            left: 100px;
            transform: rotate(180deg);
        }

        &.right {
            right: 100px;
        }
    }

    .slider {
        height: 350px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;

        @include portrait {
            height: 100vh;
            flex-direction: column;
            justify-content: center;
        }


        .container {
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;

            .item {
                width: 700px;
                height: 100%;
                background-color: rgb(145, 145, 145);
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include portrait {
                    width: 80%;
                    height: 150px;
                    margin: 15px 0;
                }

                .left {
                    height: 80%;
                    flex: 4;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .leftContainer {
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .imgContainer {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: rgb(252, 172, 143);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 10px;

                            @include portrait {
                                width: 20px;
                                height: 20px;
                            }

                            img {
                                width: 25px;

                                @include portrait {
                                    width: 15px;
                                }
                            }
                        }

                        h2 {
                            font-size: 20px;
                            margin-bottom: 10px;

                            @include portrait {
                                font-size: 13px;
                            }
                        }

                        p {
                            font-size: 13px;
                            margin-bottom: 10px;

                            @include portrait {
                                display: none;
                            }
                        }

                        span {
                            font-size: 12px;
                            font-weight: 600;
                            text-decoration: underline;
                            cursor: pointer;
                            margin-bottom: 10px;
                        }
                    }
                }

                .right {
                    height: 100%;
                    flex: 8;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 300px;
                        /*transform: rotate(-10deg);*/
                    }
                }
            }
        }
    }




}