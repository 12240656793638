@import "../../global.scss";

.intro {
  background-color: $mainColor;
  color: $mainWhite;
  display: flex;

  @include portrait {
    flex-direction: column;
    align-items: center;
  }

  @include landscape {
    flex-direction: column;
    align-items: center;
  }

  @include tablet-portrait {
    flex-direction: column;
    align-items: center;
  }

  @include tablet-landscape {
    flex-direction: column;
    align-items: center;
  }


  .left {
    flex: 0.5;
    overflow: hidden;

    .imgContainer {
      margin-top: 25px;
      width: 600px;
      height: 600px;
      background-color: rgb(128, 25, 45);
      background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
      background-size: 400% 400%;
      animation: gradient 15s ease infinite;
      border-radius: 50%;
      display: flex;
      align-items: center; //flex-start
      justify-content: center;
      float: right;

      @include portrait {
        height: 100vh;
        margin-top: 0;
        align-items: center; //flex-start
      }

      @include landscape {
        width: 300px;
        height: 300px;
        background-size: 100% 100%;
        margin-top: 30px;
        align-items: center; //flex-start
      }

      @include tablet-portrait {
        width: 400px;
        height: 400px;
        background-size: 100% 100%;
        margin-top: 30px;
        align-items: center; //flex-start
      }

      @include tablet-landscape {
        width: 500px;
        height: 500px;
        background-size: 100% 100%;
        margin-top: 30px;
        align-items: center; //flex-start
      }

      img {
        margin-top: 60px;
        height: 90%;
        margin-left: 50px;

        @include portrait {
          margin-top: 0;
          height: 50%;

        }

        @include landscape {
          margin-top: 0;
          height: 100%;
          margin-left: 0px;

        }

        @include tablet-portrait {
          margin-top: 0;
          height: 100%;
          margin-left: 0px;

        }

        @include tablet-landscape {
          margin-top: 0;
          height: 100%;
          margin-left: 0px;

        }



      }
    }

  }

  .right {
    flex: 0.5;
    position: relative;
    overflow: hidden;

    .wrapper {

      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;

      @include portrait {
        padding-left: 0;
        align-items: center;
      }

      @include landscape {
        padding-left: 0;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      @include tablet-portrait {
        margin-bottom: 50px;
        margin-top: 50px;
        width: 400px;
        height: 100%;
        padding-left: 180px;

      }

      @include tablet-landscape {
        margin-bottom: 50px;
        margin-top: 50px;
        width: 400px;
        height: 100%;
        padding-left: 150px;

      }

      @include mini-tablet-portrait {
        margin-bottom: 50px;
        margin-top: 50px;
        width: 400px;
        height: 100%;
        padding-left: 0px;

      }


      h1 {
        font-size: 60px;
        letter-spacing: 3px;
        font-family: 'Yanone Kaffeesatz', sans-serif;

        @include portrait {
          font-size: 40px;
        }

        @include landscape {
          font-size: 20px;
        }

      }

      h2 {
        font-family: 'Yanone Kaffeesatz', sans-serif;
        font-size: 35px;
        letter-spacing: 3px;

        @include landscape {
          font-size: 20px;
        }

      }

      h3 {
        font-size: 30px;
        letter-spacing: 3px;
        font-family: 'Yanone Kaffeesatz', sans-serif;

        @include portrait {
          font-size: 20px;
        }

        span {
          font-size: inherit;
          color: crimson;
          font-family: 'Yanone Kaffeesatz', sans-serif;
        }

        .ityped-cursor {
          opacity: 1;
          -webkit-animation: blink 0.3s infinite;
          -moz-animation: blink 0.3s infinite;
          animation: blink 0.3s infinite;
          animation-direction: alternate;
        }

        @keyframes blink {
          100% {
            opacity: 0;
          }
        }

        @-webkit-keyframes blink {
          100% {
            opacity: 0;
          }
        }

        @-moz-keyframes blink {
          100% {
            opacity: 0;
          }
        }
      }

      a {
        position: absolute;
        margin-top: 800px;
        left: 40%;

        img {
          width: 30px;
          height: 30px;
          animation: arrowBlink 2s infinite;
        }

        @keyframes arrowBlink {
          100% {
            opacity: 0;
          }
        }




      }
    }

  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}