@import "global.scss";

.app {
    height: 100vh;



    .sections {
        width: 100%;
        height: calc(100vh - 70px);
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        /*scroll-snap-type: y mandatory;*/
        scroll-snap-type: unset;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        >* {
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;

            @include landscape {
                height: fit-content;
                padding-left: 0;
                align-items: center;
                margin-top: 30px;
                margin-bottom: 30px;
            }

            @include tablet-portrait {
                height: fit-content;
                padding-left: 0;
                align-items: center;
                margin-top: 30px;
                margin-bottom: 30px;
            }

            @include tablet-landscape {
                height: fit-content;
                padding-left: 0;
                align-items: center;
                margin-top: 30px;
                margin-bottom: 30px;
            }

        }
    }
}